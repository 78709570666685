import { flow } from 'lodash';
import { useState, useMemo, useEffect } from 'react';
import type { Router } from 'next/router';
import { useTranslation } from 'next-i18next';
import withContainers from 'hoc/withContainers';
import withRouter from 'hoc/withRouter';
import AuthContainer from 'containers/AuthContainer';
import { logInWithCode } from 'services/authApi';
import { createInstance } from 'services/store';
import type { LoginCodeFormFields } from 'modules/auth/schemas';
import { useEventTracking } from 'modules/analytics';
import { useRecaptcha } from '../hooks';
import { CODE_ACCESS_TOKEN_STORE_KEY } from '../constants';
import { AuthPage } from './AuthPage';
import { LoginCodeForm } from './LoginCodeForm';

type LoginCodeProps = {
  containers: {
    auth: AuthContainer;
  };
  router: { history: Router };
};

const sessionStore = createInstance('session');

const LoginCodePage = ({ containers: { auth }, router: { history } }: LoginCodeProps) => {
  const { t } = useTranslation('auth');
  const [failed, setFailed] = useState(false);
  const codeAccessToken = useMemo(
    () => sessionStore.get(CODE_ACCESS_TOKEN_STORE_KEY) as string | undefined,
    [],
  );

  const { getCaptchaResponse } = useRecaptcha();
  const { track } = useEventTracking();

  useEffect(() => {
    if (!codeAccessToken) {
      history.replace('/auth');
    }
  }, [codeAccessToken, history]);

  const submit = async ({ code }: LoginCodeFormFields) => {
    setFailed(false);

    let captchaResponse: string | undefined;

    try {
      captchaResponse = await getCaptchaResponse();

      auth.setTransientAuthParams({
        accessToken: await logInWithCode(code, {
          codeAccessToken,
          captchaResponse,
        }),
      });

      sessionStore.remove(CODE_ACCESS_TOKEN_STORE_KEY);

      track({ event: 'LoginCodeSucceeded' });

      history.push('/auth/callback');
    } catch (err) {
      setFailed(true);

      track({
        event: 'LoginCodeFailed',
        codeProvided: !!code,
        accessTokenAvailable: !!codeAccessToken,
        captchaAvailable: !!captchaResponse,
      });
    }
  };

  return (
    <AuthPage
      title={t('code.title')}
      description={t('code.description')}
      invalid={failed}
      backHref="/auth"
    >
      <LoginCodeForm onSubmit={submit} failed={failed} />
    </AuthPage>
  );
};

const WrappedLoginCodePage = flow(
  withContainers({ auth: AuthContainer }),
  withRouter,
)(LoginCodePage);

export { WrappedLoginCodePage as LoginCodePage };
